/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/


/** ### LEARN SLOGAN ### */
#learnPopUp{
    position: fixed;
    top: 12vh;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    height: 80vh;
    padding: 5%;
    border-radius: 10px;
    z-index: 90;
    display: flex;
    gap: 0.7rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
}
#learnPopUp::after{
    content: '';
    position: absolute;
    top: -2vh;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    z-index: -1;
    opacity: 0.8;

}
#learnPopUp h2{
    font-size: 25px;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
    -webkit-background-clip: text;
    -webkit-border-image: fill;
}
#learnPopUp h3{
    width: 100%;
    text-align: center;
}
#learnPopUp form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}
.learnPage{
    position: relative;
}
.learnPage .video-modal{
    display: none;
    position: fixed;
    width: 90vw;
    height: 65vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 1rem;
    z-index: 1000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow);
    background-color: var(--white);
}
.learnPage .video-modal.video-modal_show{
    display: flex;
}

.learnPage .video-modal i{
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: var(--blue-mf);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.learnPage .video-modal i:hover{
    color: var(--blue-mf-hover);
    transform: scale(1.1);
}
.learnPage .video-modal i:active{
    transform: scale(0.9);
}


.learnPage #learnPopUp form
.learnPage #learnPopUp form .error{
    position: static;
    top: 0px;
    left: 0;
}

#learnPopUp form .inputContainer{
    width: 80%;
}
#learnPopUp form .error{
    color: red;
    font-size: 1.5em;
    font-weight: 300;
    text-align: center;

}
#learnPopUp form .degradedLink{
    border: none;
}
#learnPopUp form .input-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
#learnPopUp form .input-wrapper .error{
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: red;
}
.learnPage #learnPopUp form .cookies-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1% 25%;
}

.learnPage #learnPopUp form .cookies-container label{
    font-size: 15px;
}
.learnPage #learnPopUp form .cookies-container  input{
    height: 20px;
    width: 20px;
}

#learnSlogan{
    width: 90%;
    height:50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    overflow: hidden;
    padding: 0 10%;
    margin: 5% 0 0 0;
}
#learnSloganBackground{
    width: 100%;
}

#learnSlogan h2{
    font-weight: 600;
    text-align: center;
    font-size: 3em;
    z-index: 10;
}
/** ### LEARN COURSES ### */
#learnCourses{
    height: fit-content;
    width: 100%;
    min-height: 50vh;
    margin: 5% 0;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1rem;
}

#learnCoursesCard{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 90%;
    margin: 5% 0;
    min-height: 40vh;
    background: #FFFFFF;
    border: 1px solid #F3F2F2;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    border-radius: 10px;
    margin: 0;
}
#learnCoursesCard .main-info,
#learnCoursesCard .main-info .description{
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 1rem;
    margin: 0;
}
#learnCoursesCard .main-info{
    box-shadow: var(--box-shadow);
}
#learnCoursesCard .main-info .description{
    padding: 0.5rem;
}


#learnCoursesCard .main-info img{
    width: 100%;
    min-height: 190px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.learnCoursesCard_Difficulty{
    font-weight: 300;
    font-size: 1.7em;
    padding: 1% 10%;
    margin: 3% 0;
    color: white;
    border-radius: 50rem;

}
.learnCoursesCard_Difficulty--Básico{
    background: linear-gradient(90.36deg, rgba(0, 77, 255, 0.6) -0.35%, rgba(0, 77, 255, 0) 135.57%);
}
.learnCoursesCard_Difficulty--Avanzado{
    background: linear-gradient(90.36deg, rgba(255, 0, 204, 0.6) -0.35%, rgba(255, 0, 204, 0) 135.57%);
}

#learnCoursesCard .main-info .description h3{
    width: 100%;
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    height: fit-content;
    align-self: flex-start;
    margin: 0;
    padding: 0;
    color: var(--blue-mf);
}
#learnCoursesCard .main-info .description p{
    text-align: center;
    font-size: 1.3em;
    font-weight: 300;
    align-self: flex-start;
    margin: 0% 0;
}
#learnCoursesCard .degradedLink{
    align-self: flex-end;
}

#learnCoursesCard .videos-container{
    height: fit-content;
    max-height: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}
#learnCoursesCard .videos-container.videos-container_collapsed{
    max-height: 5000px;
}

#learnCoursesCard .videos-container > i{
    position: absolute;
    top: 30%;
    left: 1rem;
    color: var(--blue-mf);
    font-size: 2rem;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
#learnCoursesCard .videos-container > i:hover{
    color: var(--blue-mf-hover);
    transform: scale(1.1);
}
#learnCoursesCard .videos-container > i:active{
    transform: scale(0.9);
}

#learnCoursesCard .videos-container .fa-chevron-right{
    right: 1rem;
    left: auto;
}

#learnCoursesCard .videos-container .videos-wrapper{
    display: flex;
    overflow: hidden;
    width: fit-content;
}

#learnCoursesCard .mini-video-container{
    width: 100%;
    flex: 0 0 auto;
    row-gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 2rem 3rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
#learnCoursesCard .mini-video-container:hover{
    transform: scale(1.05);
}
#learnCoursesCard .mini-video-container:active{
    transform: scale(0.95);
}

#learnCoursesCard .mini-video-container .miniature-container{
    width: 70%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
    box-shadow: var(--box-shadow);
    border-radius: 1rem;
    position: relative;
    background-color: var(--white);
}
#learnCoursesCard .mini-video-container .miniature-container i{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%,-50%);
    color: var(--white);
    font-size: 3rem;
    font-weight: 100;
}
#learnCoursesCard .mini-video-container .miniature-container img{
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    aspect-ratio: 16/9;
}

#learnCoursesCard .mini-video-container h3{
    font-size: 1.2rem;
    color: var(--blue-mf);
    text-align: center;
}
#learnCoursesCard .mini-video-container h4{
    font-size: 0.8rem;
    text-align: center;
}

/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

    /** ### LEARN SLOGAN ### */
    #learnSlogan{
        height: 30vh;
    }

    /** ### LEARN COURSES ### */
    #learnCourses{
        min-height: 30vh;
        padding: 0 5%;
        justify-content: space-around;
    }
    #learnCoursesCard{
        width: 100%;
        margin: 0% 0%;
        /* height: 30vh; */
        /* height: 300px; */
        height: fit-content;
        min-height: 0;
        gap: 0;
    }
    #learnCoursesCard .main-info{
        flex-direction: row-reverse;
        align-items: stretch;
        height: 300px;
    }

    #learnCoursesCard .main-info img{
        height: 100%;
        width: auto;
        max-width: 300px;
    }

    .learnCoursesCard_Difficulty{
        margin: 0;
        font-size: 1.5em;
    }
    #learnCoursesCard .main-info .description{
        align-items: flex-start;
        width: 60%;
        padding: 1rem;
    }

    #learnCoursesCard .videos-container .videos-wrapper .mini-video-container .miniature-container{
        height: 200px;
    }

    #learnCoursesCard .main-info .description h3{
        font-size: 1.7em;
        text-align: left;
    }
    #learnCoursesCard .main-info .description p{
        font-size: 1.3em;
        text-align: left;
    }

    .learnPage #learnPopUp form .cookies-container label{
        font-size: 20px;
    }
    .learnPage #learnPopUp form .cookies-container{
        padding: 2% 25%;
    }
    #learnPopUp form .input-wrapper .error{
        font-size: 15px;
    }

}

/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {

    /** ### LEARN COURSES ### */
    #learnCoursesCard{
        width:100%;
        min-height: 30vh;
    }
    #learnCoursesCard .main-info .description{
        width: 70%;
    }
    #learnCoursesCard .mini-video-container{
        width: 30%;
        padding: 2rem;
    }
    #learnCoursesCard .videos-container .videos-wrapper .mini-video-container .miniature-container{
        height: 100px;
    }
    #learnCoursesCard .videos-container .videos-wrapper .mini-video-container .miniature-container img{
        aspect-ratio: unset;
    }

    #learnCoursesCardDifficulty{
        font-size: 1.5em;
        padding: 0 5%;
        margin: 3% 0
    }
    #learnCoursesCard h3{
        padding: 1% 0;
    }
    #learnCoursesCard p{
        padding: 1% 1%;
    }
    #learnCoursesCard .degradedLink a{
        padding: 2%;
    }
    #learnPopUp{
        height: 85vh;
        width: 80vw;
        border-radius: 15px;
        gap: 1rem;
    }
    #learnPopUp h2,
    #learnPopUp h3{
        margin: 0;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {
     /** ### LEARN COURSES ### */
     #learnCoursesCard .main-info img{
        max-width: 350px;
     }

     .learnPage .video-modal{
        width: 60vw;
     }

     #learnCoursesCard .videos-container .videos-wrapper .mini-video-container .miniature-container{
        height: 120px;
    }

    #learnCoursesCardDifficulty{
        font-size: 1.5em;
        padding: 0 5%;
        margin: 3% 0
    }
    #learnCoursesCard h3{
        font-size: 2.3em;
        margin: 2% 0;
    }
    #learnCoursesCard p{
        font-size: 1.5em;
        padding: 1% 1.5%;
        margin: 3% 0;
    }
    #learnCoursesCard .degradedLink{
        width: 50%;
    }
    #learnCoursesCard .degradedLink a{
        padding: 2%;

    }
    #learnPopUp{
        width: 60vw;
    }
}
/*! #######################################*/
/*! ################ MOBILE ###############*/
/*! #######################################*/

#root{
    background-color: var(--main-color);
}

/** ### SOBRE NOSOTROS ### */
#homeAbout{
    overflow: hidden;
    height: 70vh;
    margin: 0;
    padding: 1% 5%;
}
#homeAboutLink div{
    background-color: #FBFAFF;
}

#homeAboutSubtitle,
#homeAboutSubtitle sup{
    /* Efecto degradado texto */
    -webkit-text-fill-color: transparent;
    background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
    -webkit-background-clip: text;
    -webkit-border-image: fill;
}

#homeAboutParagraph{
    text-align: center;
}
/*! ### Sobre nosotros ### */


/** ### SLOGAN ###*/
#homeSlogan {
    width: 90%;
    border-radius: 15px;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    background-image: linear-gradient(145deg,
    hsl(222deg 100% 50%) 0%,
    hsl(235deg 100% 81%) 39%,
    hsl(288deg 67% 79%) 61%,
    hsl(312deg 100% 50%) 100%);

    font-size: 1.9em;
    color: #F3F2F2;
    background-size: 200% 500%;
    animation: gradient 10s ease-out infinite;
}
@keyframes gradient {
    0%{
        background-position: 0 0;
    }
    50%{
        background-position: -25rem -25rem;
    }
    100%{
        background-position: 0 0;
    }
}
#homeSlogan h2{
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0;
}


/*! ### Slogan ###*/

/** ### ¿COMO INVERTIR EN SIMPLES PASOS? ###*/

#homeInvestmentSteps{
    flex-direction: column;
    padding: 2%;
    align-items: center;
    height: auto;
    position: relative;
}
#homeInvestmentSteps_subTitle{
    text-align: center;
    font-size: 2.5em;
    letter-spacing: -2.5px;
    font-weight: 500;
    padding: 0;
    margin: 2% 0;
}
#homeCarrousel{
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    margin: 0 0 5% 0;
}
#homeCarrouselNav{
    justify-content: space-evenly;
    align-items: center;
}
#homeInvestmentSteps_desktopLink{
    display: none;
}
#homeInvestmentSteps_mobileLink{
    display: block;
    width: 40%;
    position: relative;
    bottom: 0
}
/*! ### ¿Como invertir en simples pasos? ###*/

/** ### OPORTUNIDAD DE INVERSIÓN ###*/
    #homeOportunity{
        margin: 1% 0;
    }
    #homeOportunity .sectionInfo{
        padding: 5% 0 0 0;
    }
    #homeOportunity .section_subTitle{
        font-weight: 500;
        line-height: 1.5em;
        width: 80%;
        text-align: center;
    }
    #homeOportunity .section_paragraph{
        width: 70%;
        text-align: center;
    }
    #homeOportunityLink{
        width: 45%;
    }
/*! ### Oportunidad de inversión ###*/

/** ### BENEFICIOS DE INVERTIR EN METRO FUTURO ### **/
#homeBenefits{
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
}
#homeBenefits h3{
    width: 100%;
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    height: 10%;
    color: #000F33;
    z-index: 5;
}
#homeBenefitsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    z-index: 5;
}
#homeBenefitsImg{
    display: none;
}
#homeBenefitsContainer ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0% 0;
    margin: 0 3%;
}
#homeBenefitsContainer ul li{
    display: flex;
    height: 20%;
    width: 100%;
    list-style: none;
    align-items: center;
    padding: 1% 2%;
    margin: 3% 0;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.062), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    border-radius: 10px;
    border: 1px solid #F3F2F2;
}

#homeBenefitsContainer ul li div{
    padding-left: 3%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#homeBenefitsContainer ul li img{
    width: 40px;
    height: 40px;
    margin: 0 3%;
}
#homeBenefitsContainer ul li div h4{
    font-size: 1.5em;
    font-weight: 600;
}
#homeBenefitsContainer ul li div p{
    color: #000F33;
    font-size: 1.3em;
    font-weight: 300;
}
/*! ### BENEFICIOS DE INVERTIR EN METRO FUTURO ### **/
/** ### APRENDE CON METRO FUTURO ### */
#homeLearnWithUs{
    margin: 10% 0 0 0;
    height: auto;
    flex-direction: column;
}
#homeLearnWithUs .youTubeVideo_Container{
    margin: 0;
    height: 35vh;
}
#homeLearnWithUs_Info{
    justify-content: center;
    padding: 5% 5%;
    margin: 0;
}
#homeLearnWithUs_Info h4{
    text-align: center;
    font-size: 2em;
    font-weight: 600;
}
#homeLearnWithUs_Info p {
    color: #000000;
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    padding: 2% 0;
}
/* Link degradado */
#homeLearnWithUs_Link {
    width: 30%;
}
/*! ### Aprende con Metro Futuro ### */

/** ### NUESTROS PARTNERS ### **/

#homePartners{
    height: 40vh;
    flex-direction: column;
    margin: 5% 0;
}

#homePartners h3{
    font-size: 3em;
    font-weight: 500;
    margin: 0;
}
#homePartnersContainer{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0% 3%;
}
#homePartnersContainer ul{
    position: absolute;
    left: 0;
    display: flex;
    width: 400%;
    height: 60%;
    justify-content: center;
    gap: 3%;
    align-items: flex-start;
    padding: 0 5%;
    margin: 0;
    animation: scrollPartners 20s linear infinite alternate;
    animation-timing-function: linear;
}

#homePartnersContainer li{
    list-style: none;
    width: 45%;
    height: 100%;
    max-height: 150px;
    background: #FFFFFF;
    border: 1px solid #F3F2F2;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    padding: 1%;
    scroll-snap-align: start;
    -webkit-transition: max-height 0.2s ease-in-out,width 0.2s ease-in-out,color 1s ease-in-out;
}

#homePartners a{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    padding: 0%;
}
#homePartners img{
    width: 80%;
}

@keyframes scrollPartners {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}

/*! ### Nuestros partners ### **/

/*! ########################################*/
/*! ################ TABLETS ###############*/
/*! ########################################*/

@media (min-width: 481px){
    #homeLearnWithUs .youTubeVideo_Container{
        height: 60vh;
        width: 90%;
    }
    #homeLearnWithUs .youTubeVideo_Container .youTubeVideo_Player{
        border-radius: 30px;
        height: 100% !important;
        overflow: hidden;
    }
    #homePartnersContainer li{
        max-height: 300px;
    }

    #homePartners li a img{
        width: 80%;
        object-fit: scale-down;
    }

    #homePartners.homePress li:nth-child(3) a img{
        height: 100%;
        width: auto;
    }
}


/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {
/** ### SOBRE NOSOTROS ### */
    #homeAbout{
        width: 95%;
        height: 60vh;
        padding: 2%;
    }
    #homeAbout .sectionBackground{
        width: 100%;
    }
    #homeAbout .sectionInfo{
        padding: 0;
    }
    #homeAbout #homeAboutSubtitle{
        margin: 0;
        padding: 0 0 1% 0;
        text-align: start;
        width: 90%;
    }
    #homeAbout .section_paragraph{
        width: 60%;
        font-size: 1.5em;
        line-height: 1.3em;
        text-align: start;
        padding: 0% 0 1% 0;
    }

    #homeAboutLink{
        width: 20%;
        margin: 0;
    }
    #homeAboutLink div{
        background-color: #dcdcdc;
    }
    #homeAboutLink a{
        font-size: 2em;
    }
    #homeAboutSubtitle sup,
    #homeAboutSubtitle{
        -webkit-text-fill-color: transparent;
        background: #000F33;
        -webkit-background-clip: text;
        -webkit-border-image: fill;
    }
    #homeAboutParagraph{
        text-align: start;
    }
/*! ### Sobre nosotros ### */


/** ### SLOGAN ###*/
    #homeSlogan {
        width: 95%;
        padding: 0;
        border-radius: 30px;
        height: 45vh;
    }
    @keyframes gradient {
        0%{
            background-position: 0 0;
        }
        50%{
            background-position: -25rem -25rem;
        }
        100%{
            background-position: 0 0;
        }
    }
    #homeSlogan h2{
        font-size: 2em;
        font-weight: 400;
    }

/*! ### Slogan ###*/


/** #### ¿COMO INVERTIR EN SIMPLES PASOS? ####*/
    #homeInvestmentSteps{
        /* background: linear-gradient(180deg, #FBFAFF 29.73%, #FFFFFF 100%); */
        border-radius: 150px 0px 0px 0px;
        flex-direction: row;
        justify-content: space-between;
    }
    #homeCarrousel{
        width: 85%;
        background: transparent;
    }
    #homeInvestmentSteps_subTitle{
        text-align: start;
        font-size: 3.5em;
        padding: 0 10% 3% 0;
    }
    #homeInvestmentSteps_desktopLink{
        display: block;
    }
    #homeCarrouselNav{
        justify-content: flex-end;
    }

    #homeInvestmentSteps_mobileLink{
        display: none;
    }
/*! #### ¿Como invertir en simples pasos? ####*/

/** ### OPORTUNIDAD DE INVERSIÓN ###*/

#homeOportunity {
    flex-direction: row;
    align-items: center;
    padding: 0;
    width: 95%;
}
#homeOportunity .sectionCover{
    order: 4;
}
#homeOportunity .sectionInfo{
    padding: 0 0 0 0%;
    justify-content: center;
}
#homeOportunity .section_subTitle{
    font-size: 3em;
    line-height: 1.3em;
    font-weight: 600;
    width: 95%;
    text-align: start;
    padding: 0;
}
#homeOportunity .section_paragraph{
    font-size: 2em;
    width: 95%;
    text-align: start;
    padding: 0 0 5% 0;
    font-weight: 600;
    color: #004DFF;
}
#homeOportunityLink{
    width: 40%;
    margin: 0;
}
/*! ### Oportunidad de inversión ###*/

/** ### BENEFICIOS DE INVERTIR CON METRO FUTURO ###**/
    #homeBenefits{
        padding: 0;
        margin: 5% 0;
        width: 95%;
    }
    #homeBenefits .sectionBackground--desktop{
        width: 100%;
    }

    #homeBenefits h3{
        width: 95%;
        font-size: 2.8em;
        text-align: start;
    }
    #homeBenefitsContainer{
        padding-top: 3%;
    }
    #homeBenefitsImg{
        display: block;
        width: 45%;
        justify-content: flex-end;
    }
    #homeBenefitsImg img{
        width: 60%;
    }
    #homeBenefitsContainer ul{
        width: 60%;
        padding: 0 0 0 1%;
        margin: 0;
    }
    #homeBenefitsContainer ul li{
        padding: 1% 0;
        margin: 0;
        box-shadow: none;
        border-radius: none;
        border: none;
    }

    #homeBenefitsContainer ul li div{
        padding: 0 0 0 1%;
    }
    #homeBenefitsContainer ul li div img{
        width: 50px;
        height: 50px;
    }
    #homeBenefitsContainer ul li div h4{
        color: #000F33;
        font-size: 2em;
    }
    #homeBenefitsContainer ul li div p{
        font-size: 1.7em;
        line-height: 20px;
    }
/*! ### Beneficios de invertir con Metro Futuro ###**/

/** ### APRENDE CON METRO FUTURO ### */
    #homeLearnWithUs{
        height: 35vw;
        flex-direction: row;
        margin:  0;
        width: 95%;
    }
    #homeLearnWithUs .youTubeVideo_Container{
        height: 90%;
        width: 80%;
        z-index: 10;
    }



    #homeLearnWithUs_Info{
        width: 60%;
        align-items: flex-end;
        padding: 0;
    }
    #homeLearnWithUs_Info h4{
        font-size: 3em;
    }
    #homeLearnWithUs_Info p {
        width: 90%;
        font-size: 2em;
        line-height: 1em;
        text-align: end;
        padding: 3% 0;
    }
    /* Link degradado */
    #homeLearnWithUs_Link {
        width: 20%;
        margin: 0;
    }

/*! ### Aprende con Metro Futuro ### */

/** ### NUESTROS PARTNERS ### **/

    #homePartners{
        height: 50vh;
    }

    #homePartners h3{
        font-size: 3.5em;
    }
    #homePartnersContainer{
        /* overflow: visible; */
        padding: 0;
    }
    #homePartnersContainer ul{
        /* position: static; */
        height: 100%;
        width: 200%;
        padding: 3% 5%;
    }

    #homePartnersContainer li{
        max-height: 70%;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
    }

    #homePartnersContainer li:hover{
        transform: scale(1.1);
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
    }
    #homePartners li a img{
        width: 70%;
        object-fit: scale-down;
        object-fit: contain;
    }
/*! ### Nuestros partners ### **/





}

@media (min-width: 1000px){

    #homePartners img {
        width: 100%;
    }

    #homePartnersContainer {
        display: flex;
        overflow: hidden; /* Oculta la barra de scroll */
        width: 100%;
        position: relative;
    }

    #homePartnersContainer ul {
        width: 200%;
    }
}




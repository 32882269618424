/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/

.whatsappButton{
    border-radius: 50rem;
    border: 3px solid #00BB2D;
    position: fixed;
    padding: 2%;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    z-index: 10;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsappButton_img{
    width: 40px;
}

/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

    .whatsappButton{
        right: 15px;
        bottom: 15px;
    }
    
    .whatsappButton_img{
        width: 35px;
    }
    
}
    
/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {

    .whatsappButton{
        padding: 0.8%;
        right: 20px;
        bottom: 20px;
        transition: all 1s ease;
        width: 60px;
        height: 60px;
    }
    
    .whatsappButton_img{ 
        width: 50px;
    }

    .whatsappButton:hover{
        background-color: #00BB2D;
    }

    .whatsappButton:hover .whatsappButton_img{
        filter: grayscale(100%) contrast(10000%);
    }

}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {

}
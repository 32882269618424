/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/
/** ### Course Cover ### */
.learnCoursePage_Cover{
    width: 100%;
    height: fit-content;
    align-items: center;
    position: relative;
    margin: 0;
}
.learnCoursePage_Cover div{
    position: absolute;
    bottom: -5%;
    width: 100%;
    background: linear-gradient(180deg, rgba(251, 253, 255, 0) 7.61%, rgba(251, 253, 255, 0.12709) 16.22%, rgba(251, 253, 255, 0.253148) 22.67%, rgba(251, 253, 255, 0.392475) 29.2%, rgba(251, 253, 255, 0.705621) 47.48%, #FBFDFF 60.68%, #FBFDFF 87.2%);
    height: 30%;
    z-index: 10;
}
.learnCoursePage .learnCourseCover{
    width: 100%;
    object-fit: cover;

}

/** ### Course Title ### */
.learnCoursePage_Title{
    flex-direction: column;
    align-items: flex-start;
    width: 95%;
    height: fit-content;
    margin: 0;
    padding: 0;
}
.learnCoursePage_Title h1{
    font-size: 2.1em;
    font-weight: 600;
    text-align: start;
    margin: 0;
    margin-bottom: 1rem;
}
.learnCoursePage_Title p{
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1rem;
}

/** ### Course Info ### */

.learnCoursePage_Info{
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
}
.learnCoursePage_Nav{
    width: 95%;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    padding: 0;
    border-bottom: 1px solid black;
}
.learnCoursePage_Nav li{
    width: fit-content;
    font-size: 1.3em;
    font-weight: 300;
    cursor: pointer;
 }
 .learnCoursePage_Nav .activeNav{
    font-weight: 600;
    color: black;
    border-bottom: 3px solid black;

 }
 .learnCoursePage_InfoContainer{
    width: 95%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
 }
 .learnCoursePage_InfoDescription{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
 }

 .learnCoursePage_InfoDescriptionResume{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 2% 0;
    align-items: center;
 }
 .learnCoursePage_InfoDescriptionLectureTime{
   width: 15%;
   display: flex;
   align-items: center;
 }
 .learnCoursePage_InfoDescriptionResume .learnCoursesCard_Difficulty{
    font-size: 1em;
    padding: 0.3% 3%;
    margin: 0 5% 0% 0;
    align-self: flex-start;


 }
 .learnCoursePage_InfoDescriptionResume p{
    padding: 0 1% 0 0;
    margin: 0;
    color: gray;
    font-size: 1.5em;
    font-weight: 300;
 }
 .learnCoursePage_InfoDescriptionLectureTime img{
    width: 20px;
    height: 20px;
    margin: 0 0 0 2%;
 }
 .learnCoursePage_InfoDescriptionContainer{
    width: 90%;
    font-size: inherit;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
 }
 .learnCoursePage_InfoDescriptionContainer h2{
    width: 100%;
    height: fit-content;
    padding: 0;
    margin:5% 0 2% 0;
    font-size: 2em;
    font-weight: 600;
 }
 .learnCoursePage_InfoDescriptionContainer h3{
   width: 100%;
    height: fit-content;
    margin:5% 0 2% 0;
    padding: 0;
    font-size: 1.7em;
    font-weight: 600;
 }
 .learnCoursePage_InfoDescriptionContainer h4{
   font-size: 1.7em;
   margin:5% 0 2% 0;
 }
 .learnCoursePage_InfoDescriptionContainer h5{
   font-size: 1.7em;
   margin: 5% 0 0 0;
 }
 .learnCoursePage_InfoDescriptionContainer h6{
   font-size: 1.5em;
   margin: 5% 0 0 0;
 }

 .learnCoursePage_InfoDescriptionContainer p{
    width: 100%;
    font-size: 1.3em;
    text-align: justify;
    font-weight: 300;
    margin: 1% 0 3% 0;
 }
 .learnCoursePage_InfoDescriptionContainer a{
   text-decoration: none;
   font-size: inherit;
   color: #004DFF;
  }
  .learnCoursePage_InfoDescriptionContainer a:hover{
   color: black;
  }

  .learnCoursePage_InfoDescriptionContainer ul,
  .learnCoursePage_InfoDescriptionContainer ol{
   width: 100%;
   font-size: inherit;
  }
  .learnCoursePage_InfoDescriptionContainer ul{
   list-style:initial;
  }
  .learnCoursePage_InfoDescriptionContainer li{
   list-style:inherit;
  }
  .learnCoursePage_InfoDescriptionContainer blockquote{
   width: 90%;
   padding-left: 5%;
   display: flex;
   align-items: center;
   margin: 0;
   border-left: 5px solid lightgray;
  }

  .learnCoursePage_InfoDescriptionContainer blockquote p{
   margin: 0;
  }

 .learnCoursePage_InfoDescriptionContainer img{
    max-width: 95%;
    max-height: 50vh;
    margin: 0 0 5% 0;
 }
 .learnCoursePage_InfoVideos{
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 .learnCoursePage_InfoVideoCard{
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   overflow: hidden;
   border-radius: 7px;
   box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
   margin: 0% 0 5% 0;
   cursor: pointer;
 }
 .learnCoursePage_InfoVideoCard h2{
   width: 100%;
   display: flex;
   justify-content:space-between;
   align-items: center;
   font-size: 1.3em;
   padding: 3% 3%;
   margin: 0;
 }
 .videoArrow{
   cursor:pointer;
   width: 25px;
   transition: rotate 0.3s ease-in-out;
 }
 .videoArrow--rotated{
   rotate: 180deg;
 }
 .learnCoursePage_videoContainer{
   width: 100%;
   min-height: 40vh;
   max-height: 7000px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   transition: all 0.3s ease;
   flex-wrap: wrap;
 }
 .learnCoursePage_videoContainer p{
   width: 100%;
   padding: 5%;
 }
 .learnCoursePage_videoContainer.learnCoursePage_videoContainer--wrapped{
   min-height: 0;
   max-height: 0;
 }
 .learnCoursePage_videoContainer .youTubeVideo_Player{
   width: 100%;
   min-height: 250px;
   padding: 1%;
 }

 .learnCoursePage_InfoVideos p{
    font-size: 1.5em;
 }


 .learnCoursePage_InfoAditional{
    display: flex;
    justify-content: center;
 }
 .learnCoursesCard_Type{
    font-weight: 300;
    z-index: 10;
    font-size: 1.7em;
    padding: 1% 10%;
    margin: 3% 0;
    color: white;

    border-radius: 50rem;
    background: linear-gradient(90.36deg, rgba(0, 77, 255, 0.6) -0.35%, rgba(0, 77, 255, 0) 135.57%);
 }
/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

   .learnCoursePage_InfoVideoCard h2{
      font-size: 1.5em;
    }
    .videoArrow{
      width: 30px;
    }
    .learnCoursePage_videoContainer p{
      padding: 3%;
    }
    .learnCoursePage_videoContainer{
      min-height: 70vh;
    }


}

/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {

   .learnCoursePage_Cover{
      max-height: 60vh;
      overflow: hidden;
      width: 100vw;
   }
   .learnCoursePage_Nav li{
      font-size: 1.5em;
      padding: 2% 1%;
   }
   .learnCoursePage_InfoDescriptionResume br{
      display: none;
   }
   .learnCoursePage_InfoDescription{
      padding: 0 10%;
   }
   .learnCoursePage_InfoDescriptionContainer{
      width: 100%;
   }

   .learnCoursePage_InfoDescriptionContainer h2,
   .learnCoursePage_InfoDescriptionContainer h3,
   .learnCoursePage_InfoDescriptionContainer h4,
   .learnCoursePage_InfoDescriptionContainer h5,
   .learnCoursePage_InfoDescriptionContainer h6{
      font-size: 2em;
      margin: 3% 0 0 0;
   }
   .learnCoursePage_InfoDescriptionContainer p{
      font-size: 1.7em;
      margin: 1% 0 2% 0;
   }



   .learnCoursePage_InfoDescriptionContainer img{
      max-height: 70vh
   }

   .learnCoursePage_InfoVideoCard h2{
      font-size: 1.7em;
    }
    .videoArrow{
      width: 35px;
    }
    .learnCoursePage_videoContainer .youTubeVideo_Player{
      min-height: 500px !important;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {
   .learnCoursePage_InfoDescriptionCard p{
      font-size: 1.7em;
   }

   .learnCoursePage_videoContainer .youTubeVideo_Player{
      min-height: 400px !important;
   }

   .learnCoursePage_videoContainer{
      min-height: 60vh;
   }
}
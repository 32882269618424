/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/

#contactPage{
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: #FBFDFF;
    min-height: fit-content;
    flex-wrap: wrap;
}

#contactPage section{
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 0 5%;
}

#contactPageSlogan h1{
    width: 100%;
    font-size: 2em;
    text-align: center;
}
#contactPageSlogan h1 br{
    display: none;
}
#contactPageSlogan h2{
    font-size: 1.2em;
    text-align: center;
}
#formContainer{
    width: 40%;
    flex-direction: column;
    justify-content: flex-start;
}
#formContainer form > p{
    font-size: 1.5em;
    text-align: center;
    margin: 0;
    padding: 5% 0;
    color: #003314;
    opacity: 50%;
    font-weight: 600;
}

#formContainer form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0%;
    margin: 0%;
}

.inputContainer{
    width: 100%;
    background: #004cff89;
    padding: 0;
    border-radius: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    margin: 1% 0;
    outline: none;
}
.inputLargeContainer textarea:focus,
.inputContainer input:focus{
    border: none;
    outline: none;
}

.inputContainer input{
    width: 100%;
    border-radius: 50rem;
    padding: 3% 3% 3% 5%;
    color: #000F33;
    opacity: 100%;
    border: none;
    font-size: 16px;
    color: #000F33;
}

.largeInput{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width: 100%;
    background: #004cff89;
    padding: 5px;
    border: none;
    height: 130px;
    margin: 1% 0;
}
.largeInput textarea{
    min-height: fit-content;
    border-radius: 20px;
    border: none;
    width: 100%;
    height: 100%;
    padding: 2% 2% 2% 5%;
    resize: none;
    font-size: 16px;
    color: #000F33;
    opacity: 100%;
    background-color: white;
    outline: none;
}

.input--focus {
    background: linear-gradient(to left, #FF00CC 0%, #004DFF 100%);
}
.input--valid{
    background: #76e18f;
}
.input--invalid{
    background: #ea7070;
}


::placeholder { color: #000f3377; }
.contactAttachment{
    width: 100%;
    background-color: #004cff89;
    border-radius: 50rem;
    margin: 1% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactAttachmentInput{
    display: none;
}

.contactLabelAttachment{
    width: 100% ;
    height: 100%;
    border-radius: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactLabelAttachment span{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #FBFAFF;
    margin: 2.5px 2.5px;
    padding: 1%;
    border-radius: 50rem;
}

.contactLabelAttachment span p{
    font-weight: 400;
    font-size: 16px;
    color: #000F33;
    opacity: 50%;
    padding-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactLabelAttachment span div{
    font-weight: 500;
    font-size: 16px;
    background-color: #004cff;
    border-radius: 50rem;
    padding: 2% 5%;
    color: #F3F2F2;
}

/***** Errores de contacto *****/
.contactErrors{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0%;
    margin: 0%;
}
.errorHidden{
    display: none;
}
.errorShown{
    color: red;
    display: block;
}

/** ### RECAPTCHA ### */
.recaptcha{
    width: 100%;
    height: fit-content;
    margin: 5% 0;
}
.recaptcha >div>div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.recaptcha > div > div > div{
    margin: 0 !important;

}
/*! ### RECAPTCHA ### */

/** Link degradado */

.contactSubmit {
    border: none;
    cursor: pointer;
}
.contactSubmit--success{
    width: 30% !important;
}
.contactSubmit--success img{
    background: transparent !important;
}
.contactSubmit--success p{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white !important;
    margin:0;
}
.contactSubmit--error{
    background: #FF0000;
    border-radius: 50rem !important;
    width: 50% !important;
}
.contactSubmit--error p{
    padding: 3% 5%;
    color: white !important;
    font-size: 1.3em !important;
    margin: 0;
    font-weight: 500 !important;
}
.contactSubmit--loading{
    background-color: #004DFF;
    display: flex !important;
    justify-content: center;
    align-items: center !important;;
    width: 45px !important;
    height: 45px;
    border: 5px solid white;
    border-bottom-color: transparent !important;
    border-left-color: transparent ;
    border-right-color: transparent !important;;
    border-radius: 50%;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
    animation: loader 1s linear infinite;
}
.contactSubmit--loading__rotation{
    background: white !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0;
    border-radius: 50% !important;
}

@keyframes loader {
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(360deg);
    }
}


.contactSubmit:hover div{
    background: transparent;
}
.contactSubmit div:active{
    background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
}
.contactSubmit:hover p{
    -webkit-text-fill-color: #FBFDFF;
}


/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

    #contactPage section{
        margin: 0;
        padding: 0% 10%;
    }

    #contactPageSlogan h1{
        font-size: 2.5em;
        margin: 3% 0 0 0;
    }

    #contactPageSlogan h2{
        font-size: 1.5em;
    }

    /** ### RECAPTCHA ### */
    .recaptcha{
        margin: 3% 0;
    }

    /*! ### RECAPTCHA ### */

    /** Link degradado */
    .contactSubmit {
        width: 20%;
        padding: 3px 3px;
    }

}

/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {

    #contactPage{
        padding: 5% 3%;
    }

    #contactPage .sectionBackground--desktop{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        padding: 1vh 6vw 5vh 6vw;
    }


    #contactPage section{
        width: 50%;
    }

    #contactPageSlogan{
        padding: 0 0 0 5% !important;
    }

    #contactPageSlogan h1{
        font-size: 3em;
        text-align: start;
    }
    #contactPageSlogan h1 br{
        display: block;
    }

    #contactPageSlogan h2{
        width: 100%;
        text-align: start;
    }
    #formContainer{
        padding: 0 5% !important;
    }

    /** Link degradado */
    .contactSubmit {
        width: 30%;
    }
    .contactSubmit p{
        font-size: 1.8em;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {

    #contactPageSlogan{
        padding: 0 0 0 7% !important;
    }
    #contactPageSlogan h1{
        font-size: 3.8em;
    }
    #contactPageSlogan h2{
        font-size: 2em;
    }

    .inputContainer{
        padding: 5px;
    }

    .inputLargeContainer{
        padding: 5px;
        border-radius: 30px;
    }
    .inputLargeContainer textarea{
        border-radius: 25px;
    }

    /** Link degradado */
    .contactSubmit {
        width: 17%;
        padding: 4px 4px;
    }
}
/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/

/** #### PREGUNTAS FRECUENTES ####*/
    
#homeFaq{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
}
.faqPage{
    width: 100%;
    height: fit-content;
}

#homeFaq h3{
    color: #000F33;
    font-weight: 600;
    padding: 3% 0;
}
.faqList{
    width: 95%;
    max-height: 4000px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0;
    transition: max-height 2s ease-in-out;
}

#principalFaqs{
    height: fit-content;
}

.faqItem,
.faqItem--collapsed{
    height: fit-content;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: linear-gradient(to right, #004DFF, #FF00CC);
    padding: 3px;
    white-space: nowrap;
    margin: 1% 0;
    border-radius: 13px;
    z-index: 5;
}

.faqContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #FBFDFF;
    width: 100%;
    height: fit-content;
    z-index: 5;
    border-radius: 10px;
    padding: 2% 3%;
    position: relative;
    overflow: hidden;
}

.faqQuestion{
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 0% 0%;
    display: flex;
    justify-content: space-between  ;
    align-items: center;
    flex-wrap: wrap;
    
    background: #FBFDFF;
    border-radius: 20px;
    z-index: 5;
}

.faqItem h3 {
    text-decoration: none;
    font-style: normal;
    font-size: 1.5em;
    text-align: start;
    width: 80%;
    height: auto;
    padding: 5% 0;
    margin: 0;
    font-weight: 500;
    color:#000F33;
    white-space: pre-wrap;
    z-index: 5;
}

.faqCross{
    width: 35px;
    height: 35px;
    transition: transform 0.5s ease;
    z-index: 5;
    cursor: pointer;
}
.faqCross--rotated45{
    transform: rotate(135deg);
}

.faqResponse{
    max-height: 0px;
    width: 100%;
    position: relative;
    overflow: hidden;
    font-size: 1.2em;
    font-weight: 300;
    padding: 0% 3%;
    margin: 0;
    white-space: pre-wrap;
    transition: max-height 0.7s ease-in-out;
}
.faqResponse--Expanded{
    max-height: 300px;
    transition: max-height 1s ease-in-out;
}


/***** Preguntas extra *****/

.hiddenFaqs{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.faqList--collapsed{
    max-height: 0px;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: max-height 2s ease-in;
}
#faqLink{
    width: 30%;
}

/*! #### Preguntas frecuentes ####*/



/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

    
    
}
    
/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {
/** #### PREGUNTAS FRECUENTES ####*/
    
#homeFaq h3{
    font-size: 2.5em;
    font-weight: 500;
}
.faqList{
    width: 70%;
}

.faqItem,
.faqItem--collapsed{
    padding: 4px;
    border-radius: 20px;
}

.faqContainer{
    border-radius: 16px;
}

.faqQuestion{
    padding: 0% 3%;
}

.faqItem h3 {
    font-size: 2.3em;
    width: 90%;
}

.faqCross{
    width: 55px;
    height: 55px;
}


.faqResponse{
    font-size: 1.8em;
}
/***** Preguntas extra *****/

#faqLink{
    width: 10%;
}

/*! #### Preguntas frecuentes ####*/
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {

}



/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/

:root {
    --blue-mf: #1233b5;
    --white: #FBFDFF;
    --box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
}

/** #### Estilos generales #### */
*{
    box-sizing: border-box;
}
body {
    font-family: Lexend;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.object-fit-cover{
    object-fit: cover;
}
.object-fit-contain{
    object-fit: contain;
}
.py-2{
    padding: 3% 0;
}
.div,
.sectionInfo{
    font-size: inherit;
}
.sectionInfo h2{
    font-size: 2.5em;
    font-weight: 400;
}
h3{
    font-size: 2em;
}
i{
    font-size: inherit;
}
html{
    width: 100%;
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
}
body {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    position: relative;
    background-color: #FBFAFF;
}

:root{
    width: 100%;
    height: fit-content;
    --main-color: #FBFAFF;
}

main{
    margin: 10vh 0 0 0;
    width: 100vw;
    height: fit-content;
    padding: 1vh 0 10vh 0;
    position: relative;
    background-color: #FBFDFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
}

section{
    width: 100%;
    height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 5% 0;
    z-index: 10;
}
li{
    list-style: none;
}
.hidden{
    display: none !important;
}

/** PRUEBA SCROLL **/

.topAppear{
    animation: scrollAnimation 2s;
}

@keyframes scrollAnimation{
    0%{
        transform: translateY(-200px);
    }
    100%{
        transform: translateY(0);
    }
}

/*! Prueba scroll*/



/** ### INFORMACIÓN DE LA SECCIÓN ### */
.sectionInfo{
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.sectionInfo_subsection{
    height: 100%;
    display: flex;

    justify-content: center;
    align-items: flex-start;
    z-index: 5;
}
.sectionInfo_subsection--column{
    flex-direction: column;
}
.sectionInfo_subsection--row{
    flex-direction: row;
}
.sectionInfo_subsection--100width{
    width: 100%;
}
.sectionInfo_subsection--70width{
    width: 70%;
}
.sectionInfo_subsection--60width{
    width: 60%;
}
.sectionInfo_subsection--50width{
    width: 50%;
}
.sectionInfo_subsection--40width{
    width: 40%;
}
.sectionInfo_subsection--30width{
    width: 30%;
}

/*! ### Información de la sección ### */

/** ### TIPOGRAFÍAS ### */
    h1{
        color: #000F33;
        font-size: 5em;
        font-weight: 500;
        justify-content: center;
        text-align: center;
        z-index: 10;
    }
    .section_subTitle{
        font-size: 2.5em;
        text-align: center;
        font-weight: 600;
        z-index: 10;
        padding: 2% 0;
    }
    .section_subTitle--left{
        text-align: start;
    }

    .section_paragraph{
        line-height: 1em;
        width: 100%;
        font-size: 1.8em;
        font-weight: 300;
        z-index: 10;
        padding: 2% 0;
        margin: 0;
    }

    .blackText{
        color: #000F33;
    }

    h4,
    u,
    b,
    font {
        font-size: inherit;
        display: inline;
    }
    h4,
    u,
    font {
        font-weight: inherit;
    }
    h3,
    h4,
    p{
        margin: 0;
    }
    .degradedText{
        /* Efecto degradado texto */
        margin: 0;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
        -webkit-background-clip: text;
        -webkit-border-image: fill;
        z-index: 50;
        font-size: inherit;
    }
    .degraded-blue-button{
        background: linear-gradient(to right, black 0%, var(--blue-mf) 100%);
        color: var(--white);
        border: none;
        padding: 0.5rem 2rem;
        border-radius: 2rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }
    .degraded-blue-button:hover{
        transform: scale(1.05);
    }
    .degraded-blue-button:active{
        transform: scale(0.95);
        border: none;
    }
/*! ### Tipografías ### */


/** ### BACKGROUND DE SECCIONES ###*/
    .sectionBackground{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        object-position: center;
        object-fit: cover;
        z-index: 0;
    }
    .sectionBackground--desktop{
        display: none;
    }
    .sectionBackground--mobile{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        object-position: center;
        object-fit: cover;
        z-index: 0;
    }

/*! ### Background de secciones ### */

/** ### SECTION COVER ### */
    .sectionCover{
        width: 100%;
        height: auto;
        object-fit: cover;
    }

/*! ### Section Cover ### */

/** ##### DEGRADED SEPARATOR #####*/
.degradedSeparator{
    width: 100%;
    background: linear-gradient(to left, #004DFF 0%, #FF00CC 100%);
    height: 6px;
    border-radius: 50rem;
    border: none;
    z-index: 5;
    }
/*! ##### DEGRADED SEPARATOR #####*/


/** ### CARROUSEL ### */

    .carrouselNav{
        width: 90%;
        height: auto;
        display: none;
    }
    .carrouselNav_arrowContainer{
        background: linear-gradient(to right, #004DFF, #FF00CC);
        border-radius: 50rem;
        z-index: 1;
        margin: 1%;
        cursor: pointer;
    }

    .carrouselNav_arrowContainer div{
        background: #FBFDFF;
        width: 50px;
        height: 50px;
        margin: 2px;
        border-radius: 50rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s ease-in-out;
    }
    .carrouselNav_arrowContainer img{
        width: 15px;
        transition: filter 0.2s ease-in-out;
    }
    .carrouselContainer{
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        min-height: 45vh;
        border-radius: none;
        background: #ffff;
        box-shadow: none;
        overflow-x: scroll;
        scrollbar-width: none;
        padding: 0 0 0% 0;
        margin: 0;
    }

    .carrouselContainer_mobil{
        position: absolute;
        left: 0%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        width: 250vw;
        margin: 0;
        padding: 0;
    }
    .carrousel_mobil--mobile{
        display: flex;
    }
    .carrousel_mobil--desktop{
        display: none;
    }

    .carrouselContainer_card{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 23%;
        height: 85%;
        max-height: 230px;
        box-shadow: 0px 15px 20px rgb(0 0 0 / 3%), 0px 64.8148px 46.8519px rgb(0 0 0 / 2%), 0px 38.5185px 25.4815px rgb(0 0 0 / 2%), 0px 20px 13px rgb(0 0 0 / 2%), 0px 8.14815px 6.51852px rgb(0 0 0 / 1%), 0px 1.85185px 3.14815px rgb(0 0 0 / 1%);
        border-radius: 15px;
        margin: 0 1%;
        padding: 2% 1% 1% 1%;
        background-color: #FFFFFF;
    }
    .carrouselContainer_card h3{
        color: #004DFF;
        font-size: 2em;
        font-weight: 500;
    }
    .carrouselContainer_card p{
        font-size: 1.2em;
        font-weight: 400;
        line-height: 20px;
        height: 100%;
    }
    .carrouselContainer_cardId{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 50%;
        margin: 0;
        background-color: #FFFFFF;
    }
    .carrouselContainer_cardNumber{
        background: linear-gradient(90.36deg, rgba(0, 77, 255, 0.6) -0.35%, rgba(0, 77, 255, 0) 135.57%);
        padding: 1%;
        width: 30%;
        border-radius: 50rem;
        font-size: 1.8em;
        color: #F3F2F2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
/*! ### Carrousel ### */


/** ### LINK DEGRADADO ### */


    .degradedLink{
        display: flex;
        min-width: fit-content;
        width: 60%;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to right,#004DFF, #FF00CC);
        z-index: 40;
        white-space: nowrap;
        padding: 2.5px 2.5px;
        border-radius: 50rem;
        margin: 5% 0;
        cursor: pointer;
    }
    .degradedLink_TextContainer{
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: auto;

        z-index: 50;
        border-radius: 50rem;
        transition: background-color 0.2s ease-in-out;

        cursor: pointer;
    }
    .degradedLink_TextContainer--hidden{
        display: none;
    }

    .degradedLink_TextContainer--lightGray{
        background: #dcdcdc;
    }
    .degradedLink_TextContainer--lightBlue{
        background: hsl(236deg 51% 91%);
    }
    .degradedLink_TextContainer--white{
        background: #FBFDFF;
    }

    .degradedLink:active .degradedLink_TextContainer{
        background: transparent !important;
    }


    /*? Puede ser Link(a) o Párrafo (p) según necesidad*/
    .degradedLink_Text{
        text-decoration: none;
        font-style: normal;
        font-size: 1.5em;
        margin: 0;
        width: 100%;
        height: auto;
        text-align: center;
        padding: 5%;
        font-weight: 500;
        cursor: pointer;

        /* Efecto degradado texto */
        -webkit-text-fill-color: transparent;
        background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
        -webkit-background-clip: text;
        -webkit-border-image: fill;
        z-index: 50;
    }
    .degradedLink:active .degradedLink_Text{
        -webkit-text-fill-color: #FBFDFF;
    }

    .degradedLink--inverted .degradedLink_TextContainer{
        background: transparent;
    }
    .degradedLink--inverted .degradedLink_Text{
        -webkit-text-fill-color: #FBFDFF;
    }
    .degradedLink--inverted:hover .degradedLink_TextContainer{
        background: #FBFDFF !important;
    }
    .degradedLink--inverted:hover .degradedLink_Text{
        background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-border-image: fill;
        font-weight: 600;

    }
/*! ### Link degradado ### */

/** ### YOUTUBE VIDEO ###*/
.youTubeVideo_Container{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 5;
    margin: 5% 0;
}
.youTubeVideo_Player{
    width: 100% !important;
    height: 50%;
    border: none;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*! ### YouTube Video ###*/


/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

    .carrouselContainer{
        min-height: 50vh;
    }
    .carrouselContainer_mobil{
        width: 150vw;
    }

    .youTubeVideo_Player{
        height: 70% ;
    }

}

/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {


    #NotFound404ErrorPage img{
        width: 40%;
        height: auto;
    }
    #NotFound404ErrorPage .sectionInfo h1{
        text-align: start;
        font-size: 3em;
    }
    #NotFound404ErrorPage .sectionInfo .degradedLink{
        margin: 1% 5% 0 0
    }
    #NotFound404ErrorPage .sectionInfo .degradedLink a{
        font-size: 1.7em;
    }





    section{
        width: 100%;
        height: 80vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        z-index: 1;
        margin: 0 0 5% 0;
    }
    .sectionInfo{
        align-items: flex-start;
        padding: 0 5%;
    }
    /** ### SECTION COVER ### */
        .sectionCover{
            width: 50%;
        }
    /*! ### Section Cover ### */


    .sectionBackground--desktop{
        display: block;
        width: 100vw;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }
    .sectionBackground--mobile{
        display: none;
    }

    /**  ### CARROUSEL ### */
    .carrouselNav{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .carrouselNav_arrowContainer:hover div{
        background: transparent;
    }
    .carrouselNav_arrowContainer:hover img{
        filter: grayscale(500%) brightness(600%);
    }
    .carrouselContainer{
        justify-content: center;
        min-height: 52vh;
        background: #ffff;
        border-radius: 50px;
        box-shadow: inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.3);
        overflow: hidden;
    }

    .carrouselContainer_mobil{
        align-items: center;
        height: 90%;
        width: 200%;
        transition: left 1.2s ease-out;
    }
    .carrousel_mobil--mobile{
        display: none;
    }
    .carrousel_mobil--desktop{
        display: flex;
    }
    .carrouselContainer_mobil--moved{
        left:-100%;
    }
    .carrouselContainer_card{
            width: 25%;
            height: 95%;
            max-height: 450px;
            box-shadow: 0px 100px 80px rgb(0 0 0 / 3%), 0px 64.8148px 46.8519px rgb(0 0 0 / 2%), 0px 38.5185px 25.4815px rgb(0 0 0 / 2%), 0px 20px 13px rgb(0 0 0 / 2%), 0px 8.14815px 6.51852px rgb(0 0 0 / 1%), 0px 1.85185px 3.14815px rgb(0 0 0 / 1%);
            margin: 0 1.5%;
            padding: 1% 1% 1.5% 1%;
    }
    .carrouselContainer_cardId{
        height: 40%;
    }
    .carrouselContainer_card h3{
        font-size: 2em;
    }
    .carrouselContainer_card p{
        font-size: 1.5em;
        font-weight: 300;
        height: 50%;
    }
/*! ### Carrousel ### */


    .youTubeVideo_Player{
        width: 90% !important;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {

    #NotFound404ErrorPage .sectionInfo h1{
        font-size: 4em;
    }
    #NotFound404ErrorPage .sectionInfo .degradedLink{
        width: 35%;
    }
    #NotFound404ErrorPage .sectionInfo .degradedLink a{
        font-size: 2em;
    }

    main{
        margin-top: 15vh;
        padding: 1vh 6vw 10vh 6vw;
    }


/** ### TIPOGRAFÍAS ### */

    .section_subTitle{
        font-size: 4em;
        font-weight: 500;
    }
    .section_paragraph{
        width: 70%;
        font-size: 2em;
    }
/*! ### Tipografías ### */


/** ### BACKGROUND DE SECCIONES ### */
    .sectionBackground{
        display: block;
        width: 100vw;
        object-fit: cover;
        object-position: center;
        z-index: 1;
    }

/*! ### Background de secciones ###*/

/**  ### CARROUSEL ### */
    .carrouselContainer{
        min-height: 50vh;
    }

    .carrouselContainer_mobil{
        align-items: stretch;
    }
    .carrouselContainer_mobil--moved{
        left:-100%;
    }
    .carrouselContainer_card h3{
        font-size: 2.5em;
    }
    .carrouselContainer_card p{
        font-weight: 300;
        font-size: 1.7em;
        line-height: 1.1em;
    }
    .carrouselContainer_cardId{
        height: 40%;
    }
/*! ### Carrousel ### */


/** ### LINK DEGRADADO ### */
    .degradedLink{
        width: 25%;
        margin: 0;
    }
    .degradedLink_TextContainer{
        transition: background-color 0.5s ease-in-out;
        z-index: 50;
    }

    .degradedLink:hover .degradedLink_TextContainer{
        background: transparent !important;

    }
    /* Puede ser Link(a) o Párrafo (p) según necesidad*/
    .degradedLink_Text{
        font-weight: 500;
    }

    .degradedLink:hover .degradedLink_Text{
        -webkit-text-fill-color: #FBFDFF;
    }

    /*! ### Link degradado ### */

/** ### YOUTUBE VIDEO ###*/
    .youTubeVideo_Container{
        width: 50%;
        height: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        overflow: hidden;
        z-index: 5;
    }
    .youTubeVideo_Player{
        width: 50%;
        height: 100%;
        border: none;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

/*! ### YouTube Video ###*/
}



#aboutPage{
    background-color: #E5E5E5;

}
.aboutFooterBg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #E5E5E5;
}
.aboutFooterBg--none{
    display: none;
}
.aboutPage h3{
    color: #000F33;
    font-size: 2em;
    font-weight: 500;
    display: inline;
}


/** #### SOMOS METRO FUTURO ####*/

#aboutTitle{
    flex-direction: column;
    border-radius: 30px;
    overflow: hidden;
    height: fit-content;
    width: 90vw;
    padding: 5% 0;
}
#aboutTitle .sectionBackground{
    width: 88vw;
    background-color: #FBFAFF;
    border-radius: 30px;
    height: 100%;
}
#aboutTitle p{
    text-align: center;
    width: 85%;
}
.aboutMetroLogo{
    width: 95px;
    height: auto;
    z-index: 10;
}
#aboutTitle h1{
    flex-direction: column;
    margin: 0;
    padding: 1% 0;
    font-size: 3em;
    font-weight: 600;
}
#aboutTitle h1 img{
    width: 70%;
}

/*! #### SOMOS METRO FUTURO ####*/

/** #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/
#aboutServices{
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
}

#aboutServices .sectionInfo{
    padding: 5% 0 0 0;
    width: 90%;
    justify-content: flex-start;
}

#aboutServices .sectionInfo h2{
    color: #000F33;
    font-size: 1.9em;
    font-weight: 600;
    text-align: center;
    margin: 3% 0;
}
#aboutServices .sectionInfo p{
    font-size: 1.5em;
    margin: 5% 0;;
}
#aboutServices .sectionInfo ul {
    width: 100%;

    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5% 0 0 0;
    margin: 0;
    background-color: transparent;
}
#aboutServices .sectionInfo ul li{
    display: flex;
    width: 100%;
    padding: 1% 0;
    align-items: center;
    color: #000F33;
}
#aboutServices .sectionInfo ul li img {
    width: 24px;
    height: 24px;
    margin-right: 3%;
}
#aboutServices .sectionInfo ul li p{
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5em;
    font-weight: 300;
    padding: 0;
    margin: 0;
}
/*! #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/


/** #### ¿CUAL ES NUESTRO FOCO? ####*/
#aboutFocus{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 55vh;
}

#aboutFocus h2{
    font-size: 2em;
    margin: 0;
    padding: 5% 0 0 0;
}
#aboutFocus .sectionInfo{
    overflow-x: scroll;
    scrollbar-width: 0;

    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    padding: 0 0 25% 0;
    height: 100%;
}
#aboutFocus .sectionInfo ul{
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5% 0;
    margin: 0;
    z-index: 5;
}
#aboutFocus .sectionInfo ul li{
    width: 70vw;
    min-height: 100%;
    background-color: #FBFAFF;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    padding: 2%;
    margin: 0 1%;
    border-radius: 10px;

}
#aboutFocus .sectionInfo ul li .cardTitle .cardTitle_Icon{
    width: 40px;
    height: 40px;
}
#aboutFocus .sectionInfo ul li img{
    width: 40px;
    height: auto;
}
#aboutFocus .sectionInfo ul li .cardTitle{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3% 0;
    height: 50%;
    width: 100%;

}
#aboutFocus h3{
    color: #004DFF;
    text-align: start;
    font-size: 1.7em;
    font-weight: 500;
    justify-content: flex-start;
    padding: 0 0;
    margin: 0;
}
#aboutFocus .sectionInfo ul li p{
    margin: 0;
    font-size: 1.3em;
    font-weight: 300;
    line-height: 1em;
    padding: 0% 0;
}

/*! #### ¿Cual es nuestro foco? ####*/


/** #### AGREGAMOS VALOR GENERANDO MAYOR ACCESO A LA INDUSTRIA INMOBILIARIA ####*/
#aboutInfo{
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
    padding: 5% 0;
}
#aboutInfo .sectionBackground{
    background: linear-gradient(180deg, #FBFAFF 29.73%, #FFFFFF 100%);
    border-radius: 0px 100px 0px 0px;
}
#aboutInfo .section_subTitle {
    width: 90%;
    font-size: 1.7em;
    padding: 5% 0;
    text-align: center;
}
#aboutInfo .section_paragraph{
    width: 90%;
    font-size: 1.4em;
    text-align: center;
    font-weight: 300;
    padding: 0;
    line-height: 1.2em;
}

/*! #### Agregamos valor generando mayor acceso a la industria inmobiliaria####*/

/** #### SLOGAN ####*/

#aboutSlogan{
    width: 90%;
    flex-direction: column;
    min-height: fit-content;
    max-height: 60vh;
    margin: 0% 0;
    overflow: visible;
}
.aboutSlogan h2{
    display: inline;
}

.aboutSloganText {
    width: 100%;
    height: 50vw;
    border-radius: 30px;
    background: linear-gradient(89.88deg, #004DFF -28.16%, rgba(194, 212, 255, 0.49) 100.72%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    background-size: 200% 150%;
    animation: gradient 5s ease-out infinite;
    box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
}

.aboutSloganText h2 {
    font-size: 2em;
    font-weight: 500;
    color: #F3F2F2;
    text-align: center;
}
@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: -25rem -25rem;
    }

    100% {
        background-position: 0 0;
    }
}
#aboutSlogan #aboutSloganLink{
    width: 80%;
}
/*! #### Slogan ####*/

/** #### CONOCE AL EQUIPO ####*/

#aboutTeam{
    height: fit-content;
    flex-direction: column;
    margin: 2% 0;

}
#aboutTeam .section_subTitle{
    font-size: 2em;
}

#aboutTeam ul{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3% 0;
}
.aboutTeamCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    min-height: fit-content;
    padding: 0% ;
    margin: 0% 0% ;
}

.aboutTeamImage{
    width: 150px ;
    height: 150px;
    background: linear-gradient(113.75deg, rgba(255, 178, 0, 0.6) 12.54%, rgba(117, 251, 90, 0.522) 64.51%, rgba(194, 212, 255, 0.522) 93.52%);
    border-radius: 50rem;
    margin: 20% 0 5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aboutTeamImage div{
    width: 75%;
    height: 75%;
    background-color: #FFEDC2;
    border-radius: 50rem;
    position: relative;
}
.aboutTeamImage img{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.aboutTeamInfo{
    display: flex;
    width: 100%;
    height: 14vh;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
.aboutTeamInfo *{
    text-align: center;
}
.aboutTeamInfo h3{
    width: 100%;
    font-size: 1.3em;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.aboutTeamInfo p{
    width: 100%;
    font-size: 1.3em;
    font-weight: 400;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    line-height: 1em;
    padding: 0;
    margin: 0;
}

.aboutTeamInfo a{

    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    padding: 2px;
    margin: 3% 0 0 0;
    border-radius: 50rem;
    background: linear-gradient(to right, #004DFF, #FF00CC);
    z-index: 15;
}
.aboutTeamlinkedInLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #E5E5E5;
    z-index: 5;
    border-radius: 50rem;
}

.aboutTeamlinkedInLogo img{
    width: 20px;
    height: 20px;
    z-index: 5;
}
/*! #### Conoce al equipo ####*/



/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

/** #### SOMOS METRO FUTURO ####*/


    #aboutTitle p{
        font-size: 2em
    }
    .aboutMetroLogo{
        width: 150px;
    }
    #aboutTitle h1{
        font-size: 5em;
    }
    #aboutTitle h1 img{
        width: 60%;
    }

/*! #### SOMOS METRO FUTURO ####*/

/** #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/


#aboutServices .sectionInfo h2{
    margin: 0% 0;
}
#aboutServices .sectionInfo p{
    margin: 3% 0;
}

/*! #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/

/** #### ¿CUAL ES NUESTRO FOCO? ####*/

#aboutFocus .sectionInfo{
    overflow-x: visible;
    padding: 0  0  10% 0 ;
}
#aboutFocus .sectionInfo ul{
    justify-content: center;
    align-items: stretch;
}
#aboutFocus .sectionInfo ul li{
    width: 30%;
}
#aboutFocus .sectionInfo ul li .cardTitle{
    padding: 5% 0;
}

#aboutFocus h3{
    padding: 10% 0 0 0 ;
}
#aboutFocus .sectionInfo ul li p{
    font-size: 1.4em;
}

/*! #### ¿Cual es nuestro foco? ####*/

/** #### AGREGAMOS VALOR GENERANDO MAYOR ACCESO A LA INDUSTRIA INMOBILIARIA ####*/
#aboutInfo{
    padding: 5% 0;
}
#aboutInfo .section_subTitle {
    padding: 3% 0;
}

/*! #### Agregamos valor generando mayor acceso a la industria inmobiliaria####*/


/** #### SLOGAN ####*/

#aboutSlogan{
    max-height: 50vh;
    margin: 5% 0;
}


.aboutSloganText h2 {
    font-size: 2.5em;
}
#aboutSlogan #aboutSloganLink{
    width: 35%;
}

/*! #### Slogan ####*/

/** #### CONOCE AL EQUIPO ####*/

.aboutTeamCard{
    height: fit-content;
}

.aboutTeamImage{
    margin: 15% 0 5% 0;
}

/*! #### Conoce al equipo ####*/

}






/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {
    /** ###### GENERAL #####*/


        .aboutFooterBg{
            background-color: #FBFDFF;
        }
    /*! ###### GENERAL #####*/

    /** ##### SOMOS METRO FUTURO ####*/
        #aboutPage{
            background-color: #FBFDFF;
        }
        #aboutTitle .sectionBackground{
            width: 100%;
        }
    /*! ##### SOMOS METRO FUTURO ####*/

    /** #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/
    #aboutServices{
        width: 90vw;
        flex-direction: row;
    }
    #aboutServices .sectionCover{
        border-radius: 50px;
    }
    #aboutServices .sectionInfo{
        padding: 0% 0 0% 3%;
        width: 50%;
        justify-content: center;
    }

    #aboutServices .sectionInfo h2{
        font-size: 2.2em;
        text-align: start
    }
    #aboutServices .sectionInfo p{
        font-size: 1.7em;
    }

    #aboutServices .sectionInfo ul li p{
        font-size: 1.7em;
        font-weight: 400;
    }
    /*! #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/


/** #### ¿CUAL ES NUESTRO FOCO? ####*/
#aboutFocus{
    max-height: 80vh;
    background: transparent;
}
#aboutFocus .sectionBackground--desktop{
    border-top-left-radius: 150px;
    background: linear-gradient(180deg, #FBFAFF 29.73%, #FFFFFF 100%);
}
#aboutFocus h2{
    font-size: 2.5em;
    font-weight: 600;
}
#aboutFocus .sectionInfo{
    background: transparent;
    justify-content: center;
    align-items: center;
}
#aboutFocus .sectionInfo ul{
    width: 95%;
    padding: 3% 0;
    background: transparent;
}

#aboutFocus .sectionInfo ul li .cardTitle .cardTitle_Icon{
    width: 55px;
    height: 55px;
}
#aboutFocus .sectionInfo ul li .cardTitle .cardTitle_Icon img{
    width: 55px;
}
#aboutFocus h3{
    font-size: 2.5em;
}
#aboutFocus .sectionInfo ul li p{
    font-size: 1.7em;
    line-height: 1.2em;
}

/*! #### ¿Cual es nuestro foco? ####*/


/** #### AGREGAMOS VALOR GENERANDO MAYOR ACCESO A LA INDUSTRIA INMOBILIARIA ####*/
#aboutInfo .sectionBackground{
    border-top-right-radius: 200px;
}
#aboutInfo .section_subTitle {
    font-size: 3em;
    text-align: start;
    margin: 0 0 1.5% 0 ;
}
#aboutInfo .section_paragraph{
    font-size: 1.7em;
    text-align: start;
}

/*! #### Agregamos valor generando mayor acceso a la industria inmobiliaria####*/


/** #### SLOGAN ####*/

#aboutSlogan{
    margin: 3% 0;
}

#aboutSlogan #aboutSloganLink{
    width: 30%;
}
#aboutSloganLink .degradedLink_TextContainer{
    background-color: #FBFDFF;
}
/*! #### Slogan ####*/

/** #### CONOCE AL EQUIPO ####*/
#aboutTeam h2{
    margin: 0;
}
#aboutTeam ul{
    padding: 0;
}
.aboutTeamCard{
    width: 33%;
}

.aboutTeamImage{
    margin: 10% 0 5% 0;
}
.aboutTeamInfo h3{
    font-size: 1.5em;
    padding: 1% 0;
}
.aboutTeamInfo p{
    font-size: 1.5em;
    padding: 1% 0;
}
.aboutTeamInfo a{
    margin: 5% 0 0 0;
}
.aboutTeamlinkedInLogo{
    background-color: #FBFDFF;
}
/*! #### Conoce al equipo ####*/

}




/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {

/** ##### SOMOS METRO FUTURO ####*/
    #aboutTitle p{
        font-size: 3em;
    }
/*! ##### SOMOS METRO FUTURO ####*/

/** #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/
#aboutServices{
    width: 90vw;
}
#aboutServices .sectionCover{
    border-radius: 50px;
    width: 45%;
}

#aboutServices .sectionInfo h2{
    font-size: 3em;
}
#aboutServices .sectionInfo p{
    font-size: 2em;
}

#aboutServices .sectionInfo ul li{
    padding: 2% 0;
}
#aboutServices .sectionInfo ul li img {
    width: 35px;
    height: 35px;
}
#aboutServices .sectionInfo ul li p{
    font-size: 2em;
}
/*! #### ENFOCAMOS NUESTROS ESFUERZOS EN EL MEJOR SERVICIO ####*/

/** #### ¿CUAL ES NUESTRO FOCO? ####*/
#aboutFocus{
    min-height: 95vh;
}
#aboutFocus .sectionBackground--desktop{
    border-top-left-radius: 300px;
}
#aboutFocus h2{
    font-size: 3em;
}
#aboutFocus .sectionInfo{
    padding: 0 0 5% 0;

}
#aboutFocus .sectionInfo ul{
    width: 90%;
    height: fit-content;
}
#aboutFocus .sectionInfo ul li{
    height: fit-content;
}
#aboutFocus .sectionInfo ul li .cardTitle .cardTitle_Icon{
    width: 65px;
    height: 65px;
}
#aboutFocus .sectionInfo ul li .cardTitle .cardTitle_Icon img{
    width: 65px;
}
#aboutFocus h3{
    font-size: 2.8em;
}
#aboutFocus .sectionInfo ul li p{
    font-size: 1.9em;
    height: fit-content;
}

/*! #### ¿Cual es nuestro foco? ####*/

/** #### AGREGAMOS VALOR GENERANDO MAYOR ACCESO A LA INDUSTRIA INMOBILIARIA ####*/
#aboutInfo{
    padding: 0% 0;
    overflow: visible;
}
#aboutInfo .sectionBackground{
    border-radius: 0px 300px 0px 0px;
}
#aboutInfo .section_subTitle {
    font-size: 4em;
}
#aboutInfo .section_paragraph{
    font-size: 2.5em;
}

/*! #### Agregamos valor generando mayor acceso a la industria inmobiliaria####*/

/** #### SLOGAN ####*/

.aboutSloganText {
    margin: 3% 0;
}
.aboutSloganText h2 {
    font-size: 3em;
}
#aboutSlogan #aboutSloganLink{
    width: 25%;
}

/*! #### Slogan ####*/

/** #### CONOCE AL EQUIPO ####*/
#aboutTeam .section_subTitle{
    font-size: 3em;
    margin: 0;
}

.aboutTeamImage{
    width: 250px;
    height: 250px;
}

.aboutTeamInfo h3{
    font-size: 2.5em;
}
.aboutTeamInfo p{
    font-size: 1.7em;
}
.aboutTeamInfo a{
    width: 60px;
    height: 60px;
    padding: 4px;
}

.aboutTeamlinkedInLogo img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}
/*! #### Conoce al equipo ####*/

}
/*! #######################################*/
/*! ################ MOBILE ###############*/
/*! #######################################*/

.mainFooter *{
    color: #F3F2F2;
}
.mainFooter{
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right,#C2D4FF,#004DFF);
    padding: 15vh 10vw 10vh 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height:fit-content;
    border-top-left-radius: 100px;
    position: relative;
    overflow: hidden;
}

/** ### BACKGROUNDS ### */
.mainFooterBackgroundLeft{
    position: absolute;
    border-top-left-radius: 150px;
    top: 0;
    left: 0;
    width: 250vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    z-index: 0;
    opacity: 50%;
    background-color: transparent;
}
.mainFooterBackgroundRight {
    position: absolute;
    border-top-left-radius: 150px;
    bottom: 0;
    right:  -100%;
    width: 300vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    z-index: 0;
    opacity: 50%;
}
/*! ### Backgrounds ### */


.topFooter{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 20px;
    z-index: 5;
}
.footerMetroLogo{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.footerMetroLogo h2{
    font-size: 2.5em;
}
.footerMetroLogo img{
    width: 80%;
    height: auto;
}
.footerLinks{
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
}
.footerLinks ul{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.footerLinks li{
    list-style: none;
    width: auto;
    height: auto;
    padding: 5% 0 5% 0;
}

.footerLinks a {
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 200;
}
.footerLinks h3{
    font-size: 1.5em;
}

.bottomFooter{
    border-top: 1px white solid;
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainFooter .bapps{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 5;
}
.mainFooter .bapps *{
    padding: 1%;
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 1px;
}
.mainFooter .bapps img{
    max-height: 40px;
}

.footerSocials{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 5;
}

.footerSocials a {
    padding: 2.5%;
    height: 30px;
    width: 30px;
    border: 2px white solid;
    border-radius: 50rem;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerSocials a *{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5%;
}

.footerSocials img{
    height: 17px;
    width: 17px;
    color: white;
}
.tikTokLogo{
    filter: brightness(0) invert(1);
}

/*! #######################################*/
/*! ################ DESKTOP ##############*/
/*! #######################################*/
@media (min-width: 1024px) {

    .mainFooter{
        padding: 15vh 10vw 10vh 10vw;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 150px;
    }
    /** ### BACKGROUNDS ### */
    .mainFooterBackgroundLeft{
        width: 100%;
        height: 100%;
    }
    .mainFooterBackgroundRight {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    /*! ### Backgrounds ### */


    .topFooter{
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 65px;
    }
    .footerMetroLogo{
        width: 30%;
        justify-content: center;
    }
    .footerMetroLogo img{
        width: 100%;
    }
    .footerLinks{
        width: 45%;
        justify-content: flex-end;
        align-items: flex-start;
    }
    .footerLinks ul{
        justify-content: flex-start;
        width: 50%;
    }

    .footerLinks a {
        font-size: 1.5em;
        font-weight: 300;
    }
    .footerLinks h3{
        font-size: 2em;
    }

    .bottomFooter{
        padding-top: 50px;
    }

    .mainFooter .bapps{
        width: 100%;
        flex-direction: row;
    }
    .mainFooter .bapps img{
        max-height: 70px;
    }

    .footerSocials{
        width: 90%;
        justify-content: space-between;
        align-items: flex-end;
    }

    .footerSocials a {
        height: 50px;
        width: 50px;
        border: 1.7px white solid;
        cursor: pointer;
        margin:0;
    }

    .footerSocials a *{
        cursor: pointer;
    }

}
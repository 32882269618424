/*! #########################################*/
/*! ############ MOBILE & TABLETS ###########*/
/*! #########################################*/

.header{
    width: 100vw;
    height: 10vh;
    background-color: #FBFDFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}
.headerShadow{
    width: 100%;
    height: 10vh;
    position: fixed;
    top: 0;
    z-index: 0;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
}
.headerIcons{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FBFDFF;
    z-index: 20;
    padding: 1vh 6vw 1vh 3vw;
}
.burgerMenuIcon{
    width: auto;
    display: block;
    transition: display 1s ease;
}
.burgerMenuIconHidden{
    display: none;
    transition: display 1s ease;
}
.metroFuturoLogo{
    width: auto;
    height: 90%;
    align-items: center;
    z-index: 20;
}

.header .navigation{
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5% 0 0 0;
    z-index: 10;
    gap: 20px;
    background-color: #FBFDFF;
    transition: top 0.5s ease-in-out;
    margin-block: 0;
}
.degradedSeparator--header{
    width: 75% !important;
    height: 2px !important;
    opacity: 40%;
}
.header .hiddenMenu{
    top: -800%;
}
.header .hiddenMenu li,
.header .navigation li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0% 0;
    z-index: 5;
}
li.academy-accordion{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
    cursor: pointer;
    gap: 30px;
}

.academy-accordion span,
.navLink{
    text-decoration: none;
    font-size: 2em;
    color: black;
    z-index: 5;
}
.academy-accordion span{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
}
.academy-accordion span p{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.academy-accordion span img{
    position: absolute;
    right: 0;

}
.academy-accordion .accordion-container{
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.academy-accordion ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    gap: 20px;
}
li.academy-accordion.show .accordion-container{
    max-height: 1000px;

}
.active{
    -webkit-text-fill-color: transparent;
    background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
    -webkit-background-clip: text;
    -webkit-border-image: fill;
}
.login-button-li{
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-mf);
    color: var(--white);
    border-radius: 50rem;
    height: 10%;
    max-height: 40px;
}
.login-button-li a{
    color: var(--white);
    text-decoration: none;
}
#login-button{
    /* min-width: 250px; */
    width: 100%;
}

/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

    .header .hiddenMenu{
        top: -1000%;
    }
    .login-button-li{
        width: 50%;
    }

}

/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {

    .header{
        padding: 1vh 3vw 1vh 3vw;
    }
    .degradedSeparator--header{
        display: none;
    }
    .headerShadow{
        display: none;
    }
    .metroFuturoLogo{
        width: 19vw;
        height: auto;
    }

    .burgerMenuIcon{
        display: none;
    }
    .header .hiddenMenu,
    .header .navigation{
        width: 75%;
        flex-direction: row;
        position: relative;
        justify-content: space-between;
        top: 0;
        padding: 0;
        margin: 0;
        transition: none;
        height: 100%;
    }
    .academy-accordion span,
    .navLink{
        font-size: 1.5em;
    }

    .headerIcons{
        width: fit-content;
        justify-content: flex-start;
        padding: 0;
    }
    .login-button-li{
        width: 17%;
        height: 70%;
        max-height: 80px;
    }
    .header ul.navigation li.academy-accordion{
        width: 20%;
        height: 100%;
        position: relative;
        background-color: #FBFDFF;
        z-index: 0;
        padding: 0;
    }
    .header ul.navigation.hiddenMenu li.academy-accordion span{
        height: 100%;
        background-color: #FBFDFF;
        z-index: 100;
    }
    .header ul.navigation.hiddenMenu li.academy-accordion span .videoArrow{
        width: 25px;
    }
    li.academy-accordion .accordion-container{
        position: absolute;
        width: 150%;
        background-color: #FBFDFF;
        top: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    li.academy-accordion ul{
        padding: 10% 0;
    }
    li.academy-accordion ul .degradedSeparator--header{
        display: flex;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {
    .header{
        height: 15vh;
    }
    .metroFuturoLogo{
        width: 20vw;
    }

    .header .navigation{
        width: 70%;
    }
    .header ul.navigation li.academy-accordion{
        width: 17%;
    }
    .academy-accordion span,
    .navLink{
        font-size: 1.7em;
    }


}
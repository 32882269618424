.investmentsPage{
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1vh 6vw 10vh 6vw;
    background-color: #FBFDFF;
}

.investmentsPage section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* min-height: 85vh; */
    position: relative;
}

.investmentsPage .sectionBackground{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.investmentsPage b,
.investmentsPage font {
    font-size: inherit;
    display: inline;
}

.investmentstPageTitle{
    justify-content: center;
    height: 40vh;
    flex-wrap: wrap;
    /* border: 1px solid black; */
    border-radius: 15px;
}

.investmentstPageTitle h1{
    width: 100%;
    text-align: center;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 4em;
    font-weight: 600;
}
.investmentstPageTitle font{
    padding-left: 0.6% ;
}
.investmentsPageList{
    margin: 5% 0;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
}

.investmentsPageListFilter{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5px;
    background: linear-gradient(to right, #004DFF 0%, #FF00CC 100%);
    border-radius: 15px;
    z-index: 0;
}
.investmentsPageListFilter form{
    width: 100%;
    height: 100%;
    padding: 1%;
    border-radius: 12px;
    background-color: #FBFDFF;
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.investmentsSearch{
    width: 30%;
    border-radius: 50rem;
    padding: 2px;
    height: 100%;
    background-color: #F4F4F4;
    border: none;
    outline: none;
}
.investmentsSearchFocus input,
.investmentsSearch input{
    width: 100%;
    height: 100%;
    padding: 2%;
    border-radius: 50rem;
    background-color: #F4F4F4;
    border: none;
    outline: none;
}
.investmentsSearchFocus{
    background: linear-gradient(to right,#004DFF, #FF00CC);
    width: 30%;
    border-radius: 50rem;
    padding: 2px;
    height: 100%;
    border: none;
    outline: none;
}
.priceFilter{
    width: 10%;
    height: 100%;
    margin: 0 5%;
    padding: 1% 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50rem;
    background-color: #F4F4F4;
    position: relative;
    z-index: 5;
}
.priceFilter p{
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: inherit;
}
.investmentsFilterHomeIcon{
    width: 25%;
    z-index: inherit;
}
.investmentsSearchFilterArrow{
    width: 10%;
    z-index: inherit;
}
.priceFilter ul{
    display: none;
}
.priceFilter *{
    display: block;
}
.priceFilterList{
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #F4F4F4;
    z-index: 0;
}

.priceFilter li{
    list-style: none;
    padding: 5% 1%;
    z-index: 0;
}


/* #### Tarjetas de proyecto ####*/
.investmentsPageProjectsList{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.investmentsPageProjectCard {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    min-height: fit-content;
    min-height: 50vh;
    margin: 2% 0;
    background: #FFFFFF;
    border: 1px solid #F3F2F2;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    border-radius: 10px;
    transition: width 0.5s ease-in-out;
    position: relative;
}

.investmentsPageProjectsCover{
    position: relative;
}
.investmentsPageProjectCard img{
    width: 100%;
    max-height: 25vh;
    object-fit: cover;
    border-radius: 10px;
}
.investmentsPageProjectCardAvailability{
    position: absolute;
    height: fit-content;
    width: fit-content;
    top: 2%;
    bottom:0;
    margin: 1%;
    padding: 0.5% 1%;
    font-size: 1.2em;
    color: white;
    border-radius: 50rem;
}
.investmentsPageProjectCardAvailability--false{
    background: linear-gradient(90.36deg, rgba(255, 0, 46, 0.6) -0.35%, rgba(255, 0, 46, 0) 135.57%);
}
.investmentsPageProjectCardAvailability--true{
    background: linear-gradient(90.36deg, rgba(255, 0, 204, 0.6) -0.35%, rgba(255, 0, 204, 0.024) 135.57%);
}

.investmentsPageProjectInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 3% 3%;
}
.investmentsPageProjectInfoTitle{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.investmentsPageProjectInfoTitle h2{
    font-size: 2em;
    font-weight: 600;
    margin: 1% 0;
}
.investmentsPageProjectInfoTitle h3{
    font-size: 1.6em;
    color: #000F33;
    font-style: normal;
    font-weight: 400;
    padding: 0% 0;
    margin: 1% 0;
}
.investmentsPageProjectInfoDetails{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 3% 0;
}

.investmentsPageProjectInfoDetails li{
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 0%;
}

.investmentsPageProjectInfoDetails li *{
    width: 100%;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5em;
    display: inline-block;
}
.investmentsPageProjectInfoDetails li font{
    width: 30%;
    text-align: center;
    font-weight: 600;
}
.investmentsPageProjectCard .degradedLink{
    align-self: center;
    margin: 0;
    width: 35%;
}
.investmentsPageProjectCard .degradedLink_TextContainer{
    padding: 5% 0;
}
.investmentsPageProjectCard .degradedLink_TextContainer a{
    font-weight: 600;
}


/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {

    .investmentsPageProjectCard {
        flex-direction: row;
        min-height: 20vh;
        justify-content: space-between;
        align-items: stretch;
        margin: 1% 0;
        padding: 0;
    }
    .investmentsPageProjectCard img{
        min-height: 100%;
        max-height: none;
        width: 40%;
    }

    .investmentsPageProjectInfo{
        justify-content: space-between;
        flex-direction: column;
        align-self: flex-start;
        /* margin: 0% 2% 0% 2%; */
        padding: 2%;
        width: 60%;
        /* height: 100%; */
        flex-wrap: wrap;
    }
    .investmentsPageProjectInfoTitle{
        width: 100%;
        flex-direction: row;
        padding: 0 0 3% 0;
    }
    .investmentsPageProjectInfoTitle h2{
        font-size: 1.5em;
        width: 50%;
    }
    .investmentsPageProjectInfoTitle h3{
        font-size: 1.3em;
        width: 50%;
    }
    .investmentsPageProjectInfoDetails{
        padding: 0 20% 0 0 ;
        margin: 0;
    }
    .investmentsPageProjectInfoDetails li *{
        font-size: 1.3em;
    }
    .investmentsPageProjectCard .degradedLink{
        width: 25%;
        align-self: flex-end;
    }
    .investmentsPageProjectCard .degradedLink_TextContainer{
        padding: 0% 0;
    }
    .investmentsPageProjectCard .degradedLink_TextContainer a{
        font-weight: 500;
        font-size: 1.3em;
    }
    .investmentsPageProjectInfoDetails li{
        padding: 1% 0;
    }
}

/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {

    .investmentsPageProjectCard {
        min-height: fit-content;
    }
    .investmentsPageProjectCard img{
        width: 35%;
    }

    .investmentsPageProjectInfo{
        align-self: flex-start;
        height: 100%;
        width: 65%;
    }
    .investmentsPageProjectInfoTitle h2{
        width: 70%;
    }
    .investmentsPageProjectInfoTitle h3{
        width: 30%;
    }

    .investmentsPageProjectCard .degradedLink{
        width: 20%;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {
    .investmentsPageProjectCard {
        max-height: 45vh;
    }

 /*    .investmentsPageProjectInfo{
        margin: 3% 2%;
    } */
    .investmentsPageProjectInfoTitle h2{
        font-size: 2.5em;
        width: 60%;
    }
    .investmentsPageProjectInfoTitle h3{
        font-size: 1.5em;
        width: 40%;
    }
    .investmentsPageProjectInfoDetails li *{
        font-size: 1.5em;
    }
    .investmentsPageProjectCard .degradedLink{
        padding: 0.4%;
    }
    .investmentsPageProjectCard .degradedLink_TextContainer a{
        font-size: 1.5em;
    }
}